import { lastTicketActivity, resourceUrl } from "@/utils/dataExtractors";
import { modelCaseConversion } from "@/utils/apiDataHelper";
const viewAction = (
  next: Function
) => {
  return async (data: any) => {
    // Reload the data (tiles and table)
    next(data.item, 'view')
  }
}

const archiveAction = (
  next: Function
) => {
  return async (data: any) => {
    // Archive the ticket => for that we need to delete the task
    next(data.item, 'archive')
  }
}

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.subject",
    label: "Subject",
    sortable: true,
    options: { hrefFunction: (data: any) => data.item.meta.tableRow.ticketLink }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.sellerName",
    label: "Vertrieb",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.leadName",
    label: "Lead",
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.ticketable.type",
    label: "Bezug",
    options: {
      contentFunction: (data: any) => modelCaseConversion(data.item.meta.tableRow.ticketable.type),
      hrefFunction: (data: any) => resourceUrl(data.item.meta.tableRow.ticketable)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.attributes.category",
    label: "Kategorie",
    sortable: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Nachrichten",
    key: "meta.tableRow.messageCount",
    sortable: false,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Letzte Änderung",
    key: "meta.tableRow.lastChange",
    sortable: false,
    options: {
      type: 'datetime',
      // contentFunction: (data: any) => {
      //   return lastTicketActivity(data.item.attributes.taskable)
      // }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Prio",
    key: "attributes.priority",
    options: { colorInverted: true }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   label: "Aktionen",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Ticket ansehen',
  //         icon: ['fa', 'search'],
  //         action: viewAction(next)
  //       },
  //       {
  //         title: 'Ticket Archivieren',
  //         icon: ['fa', 'archive'],
  //         action: archiveAction(next)
  //       },
  //     ]
  //   }
  // }
]
