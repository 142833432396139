import { resourceUrl } from "@/utils/dataExtractors"

const getAction = (
  next: Function
) => {
  return (data: any) => {
    next(data.item)
  }
}

export const getColumns = (
  // next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.lead.attributes.fullName",
    label: "Lead",
    sortable: true,
    options: {hrefFunction: (data: any) => {
      const lead = data.item.meta.tableRow.lead
      return resourceUrl(lead)
    }}
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "meta.tableRow.householdDesignation",
    label: "Stelle",
    sortable: true,
    options: {hrefFunction: (data: any) => {
      const posting = data.item.attributes.taskable
      return resourceUrl(posting)
    }}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.sellerName",
    label: "VP",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.lastActivation",
    label: "letzte Aktivierung",
    sortable: true,
    options: { type: 'datetime' },
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.attributes.arrivalDate",
    label: "gewünschte Ankunft",
    sortable: true,
    options: { type: 'date' },
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.visorCount",
    label: "#Agenturen",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.agencyNames",
    label: "Agenturen",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.new",
    label: "Neu",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.presented",
    label: "Vorgest.",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.rejected",
    label: "Kd. no",
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "meta.tableRow.cancelled",
    label: "Ag. no",
  },

]
