import apiClient from '@/api/apiClient'
import { fullName, defaultEmail, resourceUrl } from '@/utils/dataExtractors'

export const getAction = (
  next: Function
) => {
  return async (data: any) => {
    const href = data.item.attributes.taskable.links.memberDuplicates
    const dubLeads = await apiClient.get(href)
    next(data.item.attributes.taskable, dubLeads.data.data)
  }
}

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "taskable.attributes.address.attributes.lastName",
    label: "Leadname",
    sortable: true,
    options: {
      contentFunction: (data: any) => fullName(data.item.attributes.taskable.attributes.address),
      hrefFunction: (data: any) => resourceUrl(data.item.attributes.taskable)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "taskable.attributes.address.attributes.defaultEmail",
    label: "Email",
    sortable: true,
    options: {
      contentFunction: (data: any) => {
        const add = data.item.attributes.taskable.attributes.address.attributes
        return defaultEmail(add).email
      }
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.taskable.attributes.protoLeadsource.attributes.provider",
    label: "Leadquelle",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    label: "Aktionen",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Duplikat prüfen',
          icon: ['fa', 'search'],
          action: getAction(next)
        }
      ]
    }
  }
]
