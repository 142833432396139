
  import { defineComponent, ref, computed, getCurrentInstance } from "vue";
  import Tile from "@/components/Tile.vue"
  import { Lead } from "@/models/leads"
  import { Ticket } from "@/models/tickets"
  import { Task } from "@/models/tasks"
  import { Tile as TileModel } from "@/models/dashboard"
  import { Column } from "@/models/table";
  import apiClient from '@/api/apiClient'
  import { getRefreshData, initializeModal, addDefaultTaskColumns } from "@/view_scripts/dashboardUtils";
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";
  import { addAction } from "@/view_scripts/dashboardUtils";
  import { stringSort } from "@/utils/helper";
  import { getColumns as iLDupcliateColumns } from "./dashboard/i_l_duplicate";
  import { getColumns as iLNewColumns } from "./dashboard/i_l_new";
  import { getColumns as iLReclaimedColumns } from "./dashboard/i_l_reclaimed";
  import { getColumns as iCrNew } from "./dashboard/i_cr_new";
  import { getColumns as iPNosugColumns } from "./dashboard/i_p_nosug";
  import { getColumns as iPActiveColumns } from "./dashboard/i_p_active";
  import { getColumns as iTNotified } from "./dashboard/i_t_notified";
  import { getColumns as iPBroadcastColumns } from "./dashboard/i_p_broadcast";
  import DuplicateModal from "./dashboard/modals/DuplicateModal.vue"
  import TicketModal from "./dashboard/modals/TicketModal.vue"
  import CareReceiverAssignModal from "./dashboard/modals/CareReceiverAssignModal.vue"
  import { lastTicketActivity, fullName } from "@/utils/dataExtractors";

  export default defineComponent({
    components: { Tile },
    props: { baseData: Array },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      //Tiles
      const activeTile = ref<TileModel>()
      const setActiveTile = (tile: TileModel) => {
        activeTile.value = tile
        processTileAction()
      }
      const processTileAction = async () => {
        const tile = activeTile.value
        const response = await apiClient.get(tile?.links[tile.sysId])
        response.data.data = stringSort(response.data.data, (e) => Date.parse(e.attributes.createdAt), false)
        updateTableItems(response.data)
      }

      // Table
      const showTable = ref(false)
      const tableName = ref()
      const columns = ref<Column[]>([])
      const modalColumns = ref<Column[]>([])
      const tableItems = ref<any[]>([])
      const refreshData = getRefreshData()

      const update = () => {
        refreshData()
        processTileAction()
      }

      //Extra Table
      const sellerLeads = ref<any>([])
      const iLNewTable = () => {
        const dt = new Date();
        const todayString = `${dt.getFullYear()}-${dt.getMonth()+1}-${dt.getDate()}`
        const leadQueryParams = {
          'filter[created_at.gte]':todayString,
          'filter[seller_id.ne]':'nil',
          'page[size]': -1,
          'include': 'seller,protoLeadsource',
          'fields[sellers]': 'firstName,lastName',
          'fields[protoLeadsources]': 'sysId',
        }
        root.$store.dispatch('leads/loadv2', leadQueryParams).then((r) => {
          const leads = r.data.data
          const sellers = r.data.included.filter((r) => r.type=='sellers')
          const hpLeadsourceIds = ["59f0a7d94c73c6cc9db564d7", "5b2bb7d34c73c65c2799c7af", "5d89ddbb2c34845e57d49ebd", "5d89dde72c34845e57d49ec2", "5d89ddff2c3484411064b006", "5d89de2e2c34845e57d49ec5", "5d89de582c34845e57d49ec6", "5db020d42c34849ff0e38e35", "5db0213c2c34849ff0e38e3b", "5db2ebc92c34849ff0e39fe7", "5db95bb72c3484ba53bce444", "5dbc34602c34846ec69d3592", "5dbc34802c34846ec69d3593", "5e6257692c34849d6c7acec0", "5e6257802c34849d6c7acec2", "5f7c46e62c348410e3db9834", "5fa265c22c3484b0a51481a1", "5ea2e7952c3484edcd031403", "60599eb72c3484d014ccd52c", "61f3e4ace272d2015399d1cd", "6229e68ab055367501e5eec0", "63ed4b0b837c52b03482ed15"]
          const brokerLeadsourceIds = ["59a7cfb64c73c6d37236ce6c", "59db24504c73c61f6c2b658d", "60476d912c3484177259df78", "5ae99bfe4c73c6b18f32b25d", "5b39f5cc4c73c6fb46bf014c", "5b46ff984c73c624f221115a", "5b97ccdf4c73c6ca9259f652", "5c7c249b2c34848f63eee986", "5d1cb4132c3484567e1bcfb7", "5db99a0a2c34848a38710eea", "5de8dd502c34842248915d1a", "5e3993ef2c3484c476dd9bd3", "5e39f1132c34847c8aa732ea", "5e67b18d2c348450e24e4a8a", "606f2b86b313e51147356331", "6130a7c084e41fc44bc96870", "61eed55039b3b1202ab86f1e", "652706a17201dc04e7f727ca"]
          const inboundId = '5aeff0894c73c69afbf25ac7'
          const fields = [
            {
              key: 'sellerName',
              sortable: true,
              label: 'VP Name'
            },
            {
              key: 'assignedHP',
              sortable: true,
              label: 'ID HP'
            },
            {
              key: 'assignedBroker',
              sortable: true,
              label: 'ID Broker'
            },
            {
              key: 'purchasedHP',
              sortable: true,
              label: 'VP HP'
            },
            {
              key: 'purchasedBroker',
              sortable: true,
              label: 'VP Broker'
            },
            {
              key: 'selfCreated',
              sortable: true,
              label: 'VP Erstellt'
            },
            {
              key: 'inbound',
              sortable: true,
              label: 'inbound'
            },
            {
              key: 'total',
              sortable: true,
              label: 'Total'
            },
          ]
          const items = sellers.reduce((result, seller) => {
            const sellerName = fullName(seller)
            const sellerLeads = leads.filter((lead) => lead.relationships?.seller?.data?.id === seller.id)
            const inbound = sellerLeads.filter((lead) => lead.relationships.protoLeadsource.data.id === inboundId)
            const nonInbound = sellerLeads.filter((lead) => lead.relationships.protoLeadsource.data.id != inboundId)
            const selfCreated = nonInbound.filter((lead) => lead.relationships.creator.data.id === seller.id)
            const systemCreated = nonInbound.filter((lead) => lead.relationships.creator.data.id != seller.id)
            const purchased = systemCreated.filter((lead) => lead.attributes.purchased)
            const assigned = systemCreated.filter((lead) => !lead.attributes.purchased)
            const purchasedHP = purchased.filter((lead) => hpLeadsourceIds.includes(lead.relationships.protoLeadsource.data.id))
            const purchasedBrokerCount = purchased.length - purchasedHP.length
            const assignedHP = assigned.filter((lead) => hpLeadsourceIds.includes(lead.relationships.protoLeadsource.data.id))
            const assignedBrokerCount = assigned.length - assignedHP.length
            const row = {
              sellerName: sellerName,
              assignedHP: assignedHP.length,
              assignedBroker: assignedBrokerCount,
              purchasedHP: purchasedHP.length,
              purchasedBroker: purchasedBrokerCount,
              selfCreated: selfCreated.length,
              inbound: inbound.length,
              total: sellerLeads.length
            }
            result.push(row)
            return result
          },[])
          sellerLeads.value = {fields, items}
        })
      }
      iLNewTable()

      // Modal
      const { modalType, modalProps, addReminderAction, addDestroyTaskAction } = initializeModal(root, update)
      const modalTableItems = ref<any[]>([])
      const modalClosed = (reload?: boolean) => {
        modalProps.value = { visible: false }
        modalType.value = undefined
        if (reload) {
          refreshData()
          processTileAction()
        }
      }

      // const careReceiver = ref<any>({})
      // const crLeadId = ref<string>('')



      const updateTableItems = (
        data: Data
      ) => {
        let cols: any
        let nextAction
        let sortGetter
        switch (activeTile.value?.sysId) {
          case 'i_t_notified':
            tableName.value = 'Tickets'
            tableItems.value = stringSort((data.data as any[]), (i) => lastTicketActivity(i.attributes.taskable), false)
            nextAction = async (task: Task<Ticket>, action: string) => {
              modalType.value = TicketModal
              modalProps.value = {
                visible: true,
                action: action,
                ticket: task.attributes.taskable
              }
            }
            cols = iTNotified(nextAction)
            addDestroyTaskAction(cols)
            addReminderAction(cols)
            break
          case 'i_l_new':
            tableName.value = 'Leads ohne Vertriebler (Keine Dubletten)'
            tableItems.value = (data.data as Task<Lead>[])
            data.sellers = data.sellers.sort((a, b) => a.fullName.localeCompare(b.fullName))
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = iLNewColumns(data as any, nextAction)
            break
          case 'i_l_reclaimed':
            tableName.value = 'Erhaltene Reklamationen'
            tableItems.value = (data.data as Task<Lead>[])
            nextAction = () => {
              refreshData()
              processTileAction()
            }
            cols = iLReclaimedColumns(nextAction)
            break
          case 'i_l_duplicate':
            tableName.value = 'Lead Dubletten'
            sortGetter = (e) => Date.parse(e.attributes.taskable.attributes.createdAt)
            tableItems.value = stringSort(data.data, sortGetter, false)
            nextAction = (item: any, dupLeads: any[]) => {
              modalType.value = DuplicateModal
              modalProps.value = {
                visible: true,
                itemToCheck: item,
                modalTableItems: dupLeads
              }
            }
            cols = iLDupcliateColumns(nextAction)
            break
          case 'i_p_nocs':
            tableName.value = 'Stellenausschreibungen ohne Vorschläge'
            tableItems.value = (data.data)
            nextAction = (item: any, dupLeads: any[]) => {
              refreshData()
              processTileAction()
            }
            cols = iPNosugColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          case 'i_p_broadcast':
            tableName.value = 'Schwere oder dringende Fällt'
            tableItems.value = (data.data)
            nextAction = (item: any, dupLeads: any[]) => {
              refreshData()
              processTileAction()
            }
            cols = iPBroadcastColumns(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            addDestroyTaskAction(cols)
            break
          case 'i_p_active':
            tableName.value = 'Aktive Stellenausschreibungen (keine Wechsel)'
            tableItems.value = (data.data)
            cols = iPActiveColumns()
            break
          case 'i_cr_new':
            tableName.value = 'Erfassungsbögen ohne Lead'
            tableItems.value = (data.data)
            nextAction = async (item: any) => {
              const leadMatchesHref = item.attributes.taskable.links.lead_matches
              const leadMatchesResponse = await apiClient.get(leadMatchesHref)
              modalProps.value = {
                visible: true,
                tableItems: leadMatchesResponse.data,
                careReceiver: item.attributes.taskable
              }
              modalType.value = CareReceiverAssignModal
              // crToLeadModalVisible.value = true
              // modalTableItems.value = leadMatchData
              // modalColumns.value ({ modalRowAction: modalRowAction, data: leadMatchData })
            }
            cols = iCrNew(nextAction)
            addDefaultTaskColumns(cols)
            addReminderAction(cols)
            break
          default:
            throw Error("This Tile Action does not exist.")
        }
        columns.value = cols
        showTable.value = true
      }

      const titleActions = ref([
        {
          title: 'Dokument',
          icon: 'pencil-alt',
          tooltip: 'Dokument tooltip',
          children: [
            {
              title: 'Bearbeiten',
              icon: 'pencil-alt',
              tooltip: 'Bearbeiten tooltip',
              children: [
                {
                  title: 'Nested',
                  icon: 'pencil-alt',
                  onClick: () => alert("Nested clicked")
                }
              ]
            },
            {
              title: 'Archivieren',
              icon: 'archive',
              tooltip: 'Archivieren tooltip',
              onClick: () => alert('Dokument archivieren')
            },
            {
              title: 'Reklamieren',
              icon: 'exclamation-triangle',
              onClick: () => alert('Lead reklamieren')
            }
          ]
        },
        {
          title: 'Vertrieb',
          icon: 'address-book',
          tooltip: 'Vertrieb tooltip',
          onClick: () => alert('Vertrieb verwalten')
        },
      ])

      return {
        activeTile,
        setActiveTile,
        tableName,
        tableItems,
        showTable,
        columns,
        modalType,
        modalProps,
        modalClosed,
        modalColumns,
        modalTableItems,
        // careReceiver,
        // crLeadId,
        // launchConfirm,
        titleActions,
        sellerLeads
      }
    }
  })
