import {fullName, resourceUrl} from '@/utils/dataExtractors'
const getAction = (
  next: Function
) => {
  return (data: any) => {
    next(data.item)
  }
}

export const getColumns = (
  next: Function
) => [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "name",
    label: "Pflegebedürftige",
    sortable: true,
    options: {
      contentFunction: (data: any) => fullName(data.item.attributes.taskable) || data.item.id,
      hrefFunction: (data: any) => resourceUrl(data.item.attributes.taskable)
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "Kontakt",
  //   label: "Kontakt",
  //   sortable: true,
  //   options: {contentFunction: (data: any) => {
  //     const cr = data.item.attributes.taskable.attributes
  //     return `${cr.contactFirstName} ${cr.contactLastName}`.trim()
  //   }}
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.attributes.zipCode",
  //   label: "Ort",
  //   sortable: true,
  //   options: {
  //     contentFunction: (data: any) => {
  //       const tb = data.item.attributes.taskable.attributes
  //       return [tb.zipCode, tb.city].join(' ').trim()
  //     }
  //   }
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.attributes.contactPhone",
  //   label: "Telefon",
  //   sortable: true,
  // },
  // {
  //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
  //   key: "attributes.taskable.attributes.contactEmail",
  //   label: "E-Mail",
  //   sortable: true,
  // },
  {
    component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    key: "actions",
    label: "Aktionen",
    sortable: false,
    options: {
      buttons: [
        {
          title: 'Zum Lead zuweisen',
          icon: ['fas', 'search'],
          action: getAction(next)
        }
      ]
    }
  }
]
